import { FC, ReactElement } from 'react';
import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import { useSearchParams } from 'react-router-dom';

const App: FC = (): ReactElement => {
  const [searchParams] = useSearchParams();
  return searchParams.has('maintenanceMode', 'on') ? <ComingSoon /> : <Home />; // comment this in and resolve imports to enable maintenance mode
};
export default App;
