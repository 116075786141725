import { FC, ReactElement } from 'react';

const CTASection: FC = (): ReactElement => {
  return (
    <section className=" bg-secondary grid lg:grid-cols-12 lg:gap-x-6 lg:px-28 md:grid-cols-12 pd:gap-x-4 md:px-24 sm:grid-cols-6 sm:gap-x-2 sm:px-4 grid-cols-6 gap-x-4 px-2 py-8">
      <div className="col-span-12 py-4">
        <h2 className="text-center mx-auto text-white my-4">
          We would love to hear from you so don't delay and get in touch!
        </h2>
      </div>
      <div className="col-span-12 py-4 flex justify-center">
        <a
          href="https://clientportal.uk.powerdiary.com/clientportal/fcpsc"
          className="w-[50%] mx-auto p-4 text-center rounded-3xl bg-primary focus:outline-yellow-500 text-white font-semibold disabled:bg-slate-500"
        >
          Book your appointment today
        </a>
      </div>
    </section>
  );
};

export default CTASection;
