import { FC, ReactElement } from 'react';

const MapSection: FC = (): ReactElement => {
  return (
    <div className="gmap_canvas">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d619.8775834451437!2d0.08397148855521158!3d51.57720961502226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a74b6038f7a1%3A0x4c42ab06cba426f3!2sFirst%20Care%20Physiotherapy%20%26%20Sports%20Clinic!5e0!3m2!1sen!2suk!4v1706826707690!5m2!1sen!2suk"
        className="h-[400px] pointer-events-auto"
        width="100%"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapSection;
