import { ReactElement } from 'react';
import SocialNavSection from './sections/MobileSocialNav';
import MobileSocialNavSection from './sections/MobileSocialNav';
import useScreenSize from '../hooks/useScreenSize';
import logoRed from '../assets/logo-green.svg';
import { useTranslation } from 'react-i18next';
import Footer from './sections/Footer';

const PrivacyPolicy = (): ReactElement => {
  const { width } = useScreenSize();
  const { t } = useTranslation();

  const sections = t('privacyPolicy.sections', {
    returnObjects: true
  }) as Array<{
    title: string;
    parts: Array<{ type: string; text?: string; items?: string[] }>;
  }>;

  return (
    <div className="lg:max-w-[1440px] max-w-full mx-auto">
      {width >= 968 && <SocialNavSection />}
      <section>
        <nav className="px-4 py-4 sm:px-8 flex flex-row justify-between">
          <div className="flex flex-row justify-center self-center">
            <div className="flex flex-row justify-center">
              <img src={logoRed} width={65} height={65} />
              <h1 className="break-words text-sm sm:text-lg md:text-4xl/tight font-semibold text-primary my-auto self-center">
                First Care Physiotherapy & Sports Clinic
              </h1>
            </div>
          </div>
          {width >= 960 ? null : (
            <div className="lg:invisible md:invisible sm:visible xs:visible self-center">
              <a
                href="https://clientportal.uk.powerdiary.com/clientportal/fcpsc"
                className="whitespace-nowrap text-white no-underline bg-primary rounded-lg hover:bg-secondary hover:underline hover:text-blue-200 text-xs sm:text-sm md:text-base p-4"
              >
                Book Today
              </a>
            </div>
          )}
        </nav>
      </section>
      {width < 968 && <MobileSocialNavSection />}
      <div className="grid lg:grid-cols-12 lg:gap-x-6 lg:mx-28 md:grid-cols-12 md:gap-x-4 md:mx-24 sm:grid-cols-6 sm:gap-x-2 sm:mx-4 xs:grid-cols-6 xs:gap-x-4 xs:mx-2 p-6">
        <h1 className="col-span-12">Privacy Policy</h1>
        {sections.map((section, sectionIndex) => (
          <>
            <h2 key={`section-title-${sectionIndex}`} className="col-span-12">
              {section.title}
            </h2>
            <div key={`section-part-${sectionIndex}`} className="col-span-12">
              {section.parts.map((part, partIndex) => {
                if (part.type === 'UNORDERED_LIST') {
                  return (
                    <ul
                      key={`section-${sectionIndex}-part-${partIndex}-list`}
                      className="col-span-12 list-disc ml-5"
                    >
                      {part.items?.map((i, listItem) => (
                        <li
                          key={`section-${sectionIndex}-part-${partIndex}-list-item-${listItem}`}
                        >
                          {i}
                        </li>
                      ))}
                    </ul>
                  );
                } else {
                  return (
                    <p
                      key={`section-${sectionIndex}-part-${partIndex}-paragraph`}
                      className="col-span-12"
                    >
                      {part.text}
                    </p>
                  );
                }
              })}
            </div>
          </>
        ))}
      </div>
      <section className="bg-[rgba(176,187,168,0.18)]"></section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
