import { FC, ReactNode, MouseEvent, useState } from 'react';
import logo from '../assets/logo-white.svg';
import openEmailClient from '../helpers/openEmailClient';

const ComingSoon: FC<{ children?: ReactNode }> = () => {
  const [email, setEmail] = useState<string | undefined>('');

  function handleSubmit(event: MouseEvent<HTMLInputElement>): void {
    event.preventDefault();
    openEmailClient();
  }

  return (
    <div className="flex flex-col items-center justify-center bg-primary h-screen">
      <div>
        <img src={logo} />
      </div>
      <h1 className="text-4xl my-5 font-bold">Site Coming Soon</h1>
      <div className="my-2">
        <div className="px-5">
          <div className="my-5">
            <p className="text-base text-center">
              Welcome visitor our site is currently under construction please
              bear with us.
            </p>
            <p className="text-base text-center">
              If you want to subscribe or get in touch then just drop your email
              in below and we can get in touch as soon as we can.
            </p>
          </div>
          <div className="flex flex-row bg-secondary p-3 rounded-md">
            <div className="border-2 border-white rounded-md flex flex-row w-full">
              <div className="w-3/5">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="rounded-md rounded-r-none p-2 border-none outline-none w-full text-slate-700"
                  placeholder="Enter your email"
                />
              </div>
              <div className="w-2/5">
                <input
                  type="button"
                  value="submit"
                  onClick={handleSubmit}
                  disabled={email === undefined || email === ''}
                  className="bg-grey p-2 border-white border-none outline-none w-full disabled:bg-slate-500"
                />
              </div>
            </div>
          </div>
          <p className="text-base text-center text-slate-200">
            By entering your email address and submitting your details you agree
            to our privacy policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
