import { FC, ReactElement } from 'react';

const Footer: FC = (): ReactElement => {
  const openingTimes: {
    [day: string]: { closed: boolean; startTime?: string; endTime?: string };
  } = {
    sunday: {
      closed: false,
      startTime: '12:00',
      endTime: '18:00'
    },
    monday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    },
    tuesday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    },
    wednesday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    },
    thursday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    },
    friday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    },
    saturday: {
      closed: false,
      startTime: '10:00',
      endTime: '19:00'
    }
  };

  return (
    <footer className="bg-primary text-white grid lg:grid-cols-12 lg:gap-x-6 lg:px-28 md:grid-cols-12 md:gap-x-4 md:px-24 sm:grid-cols-6 sm:gap-x-2 sm:px-4 grid-cols-6 gap-x-4 px-2 py-8 grid-auto-rows">
      <div className="lg:col-start-3 lg:col-span-4 md:col-start-3 md:col-span-6 sm:col-start-2 sm:col-span-4 col-start-2 col-span-4 py-4">
        <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col gap-8">
          <div>
            <h3 className="font-bold">Services</h3>
            <ul>
              {[
                'Physiotherapy',
                'Clinical',
                'Pilates Massage',
                'Therapy',
                'Personal Training',
                'Cycling',
                'Ergonomic Assessments & Work Injuries',
                'Osteopathy',
                'Exercise Rehab',
                'Podiatry',
                'Sports Massage',
                'Dry Needling',
                'Kineso Taping',
                'Cupping'
              ].map((item, index) => (
                <li key={`${item}-${index}`}>{item}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-bold">Opening Hours</h3>
            <ul>
              {Object.keys(openingTimes).map((dayString: string) => (
                <li>{`${dayString.charAt(0).toUpperCase()}${dayString.slice(
                  1
                )}: ${openingTimes[dayString].startTime} - ${
                  openingTimes[dayString].endTime
                }`}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:col-start-3 lg:cols-span-8 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-4 col-start-2 col-span-4">
        <div className="flex flex-row flex-wrap justify-between">
          <p>All rights reserved</p>
          <p>&copy; First Care Physiotherapy & Sports Clinic 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
