/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactElement } from 'react';
// import openEmailClient from '../../helpers/openEmailClient';

const AboutUsSection: FC = (): ReactElement => {
  // const [formState, updateFormState] = useState<{
  //   name?: string;
  //   email?: string;
  //   phone?: string;
  //   message?: string;
  // }>({
  //   name: undefined,
  //   email: undefined,
  //   phone: undefined,
  //   message: undefined
  // });

  // const updateDetails = (input: string, value?: string) => {
  //   updateFormState((p) => ({
  //     ...p,
  //     [input]: value === '' ? undefined : value
  //   }));
  // };

  // function handleSubmit(event: MouseEvent<HTMLButtonElement>): void {
  //   event.preventDefault();
  //   openEmailClient();
  // }

  return (
    <section className="bg-[rgba(176,187,168,0.18)]">
      <div className="grid lg:grid-cols-12 lg:gap-x-6 lg:mx-28 md:grid-cols-12 md:gap-x-4 md:mx-24 sm:grid-cols-6 sm:gap-x-2 sm:mx-4 xs:grid-cols-6 xs:gap-x-4 xs:mx-2">
        {/* <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 m-4"> */}
        <div className="col-span-12 m-4">
          <h2 className="text-left font-semibold text-secondary">About Us</h2>
          <p className="text-left text-gray-400">
            At First Care Physiotherapy, we believe in working with each
            individual in order to aid them achieve their goals.
          </p>
          <p className="text-left text-gray-400">
            Each assessment and therapy is tailored to the individual, as your
            physiotherapist collaborates with you to better understand you, your
            goals, and to develop a care plan in the hopes of bringing you back
            to living life on your terms.
          </p>
        </div>
        {/* <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 xs:col-span-6 m-4">
          <div className="flex flex-col gap-4">
            <h2 className="text-left font-semibold text-secondary">
              Ask a question
            </h2>
            <form>
              <div className="flex flex-col gap-4">
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    onBlur={(e) => updateDetails('name', e.target.value)}
                    className="w-full p-4 rounded-lg border-2 border-secondary focus:outline-yellow-500 text-neutral-700"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    onBlur={(e) => updateDetails('email', e.target.value)}
                    className="w-full p-4 rounded-lg border-2 border-secondary focus:outline-yellow-500 text-neutral-700"
                  />
                </div>
                <div>
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                    onBlur={(e) => updateDetails('phone', e.target.value)}
                    className="w-full p-4 rounded-lg border-2 border-secondary focus:outline-yellow-500 text-neutral-700"
                  />
                </div>
                <div>
                  <textarea
                    name="message"
                    form=""
                    rows={5}
                    placeholder="Please enter your message"
                    onBlur={(e) => updateDetails('message', e.target.value)}
                    className="w-full p-4 rounded-lg border-2 border-secondary focus:outline-yellow-500 resize-none text-neutral-700"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="w-full p-4 rounded-3xl bg-secondary focus:outline-yellow-500 text-white font-semibold disabled:bg-slate-500"
                    disabled={Object.values(formState).some(
                      (item) => item === undefined
                    )}
                    onClick={handleSubmit}
                  >
                    Send Questions
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUsSection;
