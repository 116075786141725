import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as en_GB from './assets/i18n/en-GB.json';

const resources: Resource = {
  ['en-GB']: {
    translation: en_GB
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en-GB',
    debug: false,
    resources,
    interpolation: {
      escapeValue: false
    }
  });
