import { Card } from '@fcpsc/components';
import { FC, ReactElement } from 'react';

import physioImage from '../../assets/img/physio-back-care.jpg';
import physioStretch from '../../assets/img/physio-stretch.png';
import ladyExercising from '../../assets/img/lady-exervising.png';
import backPain from '../../assets/img/back-pain.png';

const Services: FC = (): ReactElement => {
  return (
    <section>
      <h2 className="break-words text-3xl sm:text-4xl/tight md:text-4xl/tight text-center pt-12 font-bold">
        Our Services
      </h2>
      <div className="grid grid-rows-1 grid-cols-12 gap-4 sm:grid-rows-2 sm:grid-cols-12 sm:p-16 md:grid-rows-1 md:grid-cols-12 lg:grid-rows-1 lg:grid-cols-12 p-12">
        <div className="col-span-12 sm:col-span-6 sm:row-span-1 md:col-span-3 md:row-span-1 lg:col-span-3 lg:row-span-1">
          <Card
            image={<img src={physioImage} />}
            content={
              <>
                <h3>Sports massage</h3>
                <p>
                  Sports massage targets athletes and active individuals,
                  employing various techniques to enhance performance, aid
                  recovery, and prevent injuries. It focuses on specific muscle
                  groups used during sports, promoting flexibility, reducing
                  muscle tension, and improving circulation to optimize athletic
                  performance and overall well-being.
                </p>
              </>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 sm:row-span-1 md:col-span-3 md:row-span-1 lg:col-span-3 lg:row-span-1">
          <Card
            image={<img src={physioStretch} />}
            content={
              <>
                <h3>Osteopathy</h3>
                <p>
                  Osteopathy is a holistic healthcare approach that emphasizes
                  the body's self-healing abilities. Through manual manipulation
                  of the musculoskeletal system, osteopaths aim to restore
                  balance, alleviate pain, improve mobility, and promote overall
                  wellness. This gentle, hands-on therapy considers the
                  interconnectedness of body systems to address a wide range of
                  conditions, from musculoskeletal issues to respiratory and
                  digestive concerns.
                </p>
              </>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 sm:row-span-1 md:col-span-3 md:row-span-1 lg:col-span-3 lg:row-span-1">
          <Card
            image={<img src={backPain} />}
            content={
              <>
                <h3>Physiotherapy</h3>
                <p>
                  Physiotherapy is a healthcare profession focusing on restoring
                  movement and function. Using evidence-based techniques,
                  physiotherapists assess and treat a variety of conditions,
                  including musculoskeletal injuries, neurological disorders,
                  and respiratory issues. Through personalized exercise
                  programs, manual therapy, and education, physiotherapy aims to
                  optimize physical health and well-being.
                </p>
              </>
            }
          />
        </div>
        <div className="col-span-12 sm:col-span-6 sm:row-span-1 md:col-span-3 md:row-span-1 lg:col-span-3 lg:row-span-1">
          <Card
            image={<img src={ladyExercising} />}
            content={
              <>
                <h3>Kineso Taping</h3>
                <p>
                  Kinesio Taping is a therapeutic technique that uses elastic
                  tape to support muscles and joints, alleviate pain, and aid in
                  injury recovery. By providing gentle, targeted support,
                  Kinesio Tape can enhance proprioception, promote circulation,
                  and facilitate natural movement patterns, allowing individuals
                  to maintain or improve performance during activities of daily
                  living or sports.
                </p>
              </>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Services;
