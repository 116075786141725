import { FC, ReactElement } from 'react';
import useScreenSize from '../../hooks/useScreenSize';

const Landing: FC = (): ReactElement => {
  const { width } = useScreenSize();
  return (
    <section className="relative bg-[url(https://plus.unsplash.com/premium_photo-1661716849429-6796ea134487?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)] bg-cover bg-center bg-no-repeat">
      <div className="absolute inset-0 bg-opacity-70 bg-gray-800"></div>
      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
        <div className="mx-auto text-center">
          <h1 className="text-3xl font-extrabold sm:text-5xl text-white">
            Heal | Restore | Revive
            <strong className="block font-extrabold text-white">
              Your Social Being.
            </strong>
          </h1>
          <p className="mt-4 sm:text-xl/relaxed text-center text-white">
            Physiotherapy is both an art and a science that aids in the
            rehabilitation of a wide range of conditions.
          </p>
          <p className="mt-4 sm:text-xl/relaxed text-center text-white">
            We believe in working with each individual in order to aid them
            achieve their goals.
          </p>
          <p className="mt-4 sm:text-xl/relaxed text-center text-white">
            Each assessment and therapy is tailored to the individual by
            developing a care plan to help with their recovery.
          </p>
          {width >= 960 && (
            <div className="mt-8 flex flex-wrap gap-4 justify-center text-center">
              <a
                href="https://clientportal.uk.powerdiary.com/clientportal/fcpsc"
                className="block w-full rounded-lg bg-secondary px-12 py-3 text-sm font-bold text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                Book an appointment
              </a>

              <a
                href="#"
                className="block w-full rounded-lg bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
              >
                Learn More
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Landing;
