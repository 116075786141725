import { SocialNav } from '@fcpsc/components';
import { FC, ReactElement } from 'react';

const SocialNavSection: FC = (): ReactElement => (
  <section className="bg-primary w-full lg:visible md:visible sm:invisible xs:invisible">
    <div className="grid md:grid-cols-12 md:gap-4 md:w-4/5 md:mx-auto sm:grid-cols-6 sm:w-full bg-primary text-slate-800">
      <div className="col-span-12 md:col-span-12 sm:col-span-6 bg-primary text-white p-3">
        <SocialNav />
      </div>
    </div>
  </section>
);
export default SocialNavSection;
