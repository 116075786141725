import { FC } from 'react';
import vitalityHealth from '../../assets/vitality.svg';
import aviva from '../../assets/aviva.svg';
import hcpc from '../../assets/hcpc-registered.svg';

const Partners: FC = () => (
  <div className="relative mx-auto max-w-screen-xl px-4 py-4 sm:px-6 lg:flex lg:items-center lg:px-8 flex flex-col">
    <div>
      <h2 className="text-center font-semibold text-secondary">We work with</h2>
    </div>
    <div className="mx-auto grid grid-cols-3 gap-6">
      <img
        src={vitalityHealth}
        alt="Vitality Health Logo"
        className="mx-auto h-[80%] w-[80%]"
      />
      <img
        src={aviva}
        alt="Vitality Health Logo"
        className="mx-auto h-[80%] w-[80%]"
      />
      <img
        src={hcpc}
        alt="Hcpc Health Logo"
        className="mx-auto h-[80%] w-[80%]"
      />
    </div>
  </div>
);

export default Partners;
