import { FC, ReactElement } from 'react';

const openingTimes: {
  [day: string]: { closed: boolean; startTime?: string; endTime?: string };
} = {
  sunday: {
    closed: false,
    startTime: '12:00',
    endTime: '18:00'
  },
  monday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  },
  tuesday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  },
  wednesday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  },
  thursday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  },
  friday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  },
  saturday: {
    closed: false,
    startTime: '10:00',
    endTime: '19:00'
  }
};

const getOpeningTimeString = (): string => {
  const day = new Date().getDay();
  const dayName = Object.keys(openingTimes)[day];
  return openingTimes[dayName].closed
    ? 'Closed'
    : `${dayName.charAt(0).toUpperCase()}${dayName.slice(1)}: ${
        openingTimes[dayName].startTime
      } - ${openingTimes[dayName].endTime}`;
};

const SocialNavSection: FC = (): ReactElement => (
  <section className="bg-primary w-full lg:invisible md:invisible sm:visible xs:visible">
    <div className="grid md:grid-cols-12 md:gap-4 md:w-4/5 md:mx-auto sm:grid-cols-6 sm:w-full bg-primary text-slate-800">
      <div className="col-span-12 md:col-span-12 sm:col-span-6 bg-primary text-white p-3">
        <div className="flex flex-row justify-between">
          <div>
            <div className="flex gap-1">
              <svg
                className="fill-white"
                width="14"
                height="14"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              <div>
                <p className="text-base/none">
                  <a href="tel:0207 062 7106">0207 062 7106</a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row content-start">
            <div className="flex flex-row content-start mr-2">
              <div className="flex gap-1">
                <svg
                  className="fill-slate-300 self-center"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 14.7947C3.11719 14.7947 0 11.6775 0 7.79468C0 3.93921 3.11719 0.794678 7 0.794678C10.8555 0.794678 14 3.93921 14 7.79468C14 11.6775 10.8555 14.7947 7 14.7947ZM6.34375 7.79468C6.34375 8.01343 6.45312 8.23218 6.61719 8.34155L9.24219 10.0916C9.54297 10.3103 9.95312 10.2283 10.1445 9.92749C10.3633 9.62671 10.2812 9.21655 9.98047 8.9978L7.65625 7.46655V4.07593C7.65625 3.72046 7.35547 3.41968 6.97266 3.41968C6.61719 3.41968 6.31641 3.72046 6.31641 4.07593L6.34375 7.79468Z"
                    fill="white"
                  />
                </svg>

                <div>
                  <p className="text-base/none">{getOpeningTimeString()}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-row content-start pl-2 border-l-2 border-white gap-2">
              <a
                className="self-center"
                href="https://www.facebook.com/firstcarephysiosportsclinic"
              >
                <svg
                  className="fill-slate-300"
                  width="9"
                  height="16"
                  viewBox="0 0 9 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.17383 8.71118H5.97656V15.2737H3.04688V8.71118H0.644531V6.01587H3.04688V3.93579C3.04688 1.59204 4.45312 0.273682 6.5918 0.273682C7.61719 0.273682 8.70117 0.47876 8.70117 0.47876V2.79321H7.5C6.32812 2.79321 5.97656 3.49634 5.97656 4.25806V6.01587H8.58398L8.17383 8.71118Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                className="self-center"
                href="https://www.instagram.com/firstcarephysiosportsclinic"
              >
                <svg
                  className="fill-slate-300 self-center"
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5625 3.40454C9.4082 3.40454 10.9316 4.92798 10.9316 6.77368C10.9316 8.64868 9.4082 10.1428 7.5625 10.1428C5.6875 10.1428 4.19336 8.64868 4.19336 6.77368C4.19336 4.92798 5.6875 3.40454 7.5625 3.40454ZM7.5625 8.97095C8.76367 8.97095 9.73047 8.00415 9.73047 6.77368C9.73047 5.57251 8.76367 4.60571 7.5625 4.60571C6.33203 4.60571 5.36523 5.57251 5.36523 6.77368C5.36523 8.00415 6.36133 8.97095 7.5625 8.97095ZM11.8398 3.28735C11.8398 2.8479 11.4883 2.49634 11.0488 2.49634C10.6094 2.49634 10.2578 2.8479 10.2578 3.28735C10.2578 3.72681 10.6094 4.07837 11.0488 4.07837C11.4883 4.07837 11.8398 3.72681 11.8398 3.28735ZM14.0664 4.07837C14.125 5.16235 14.125 8.41431 14.0664 9.49829C14.0078 10.553 13.7734 11.4612 13.0117 12.2522C12.25 13.0139 11.3125 13.2483 10.2578 13.3069C9.17383 13.3655 5.92188 13.3655 4.83789 13.3069C3.7832 13.2483 2.875 13.0139 2.08398 12.2522C1.32227 11.4612 1.08789 10.553 1.0293 9.49829C0.970703 8.41431 0.970703 5.16235 1.0293 4.07837C1.08789 3.02368 1.32227 2.08618 2.08398 1.32446C2.875 0.562744 3.7832 0.328369 4.83789 0.269775C5.92188 0.211182 9.17383 0.211182 10.2578 0.269775C11.3125 0.328369 12.25 0.562744 13.0117 1.32446C13.7734 2.08618 14.0078 3.02368 14.0664 4.07837ZM12.6602 10.6409C13.0117 9.79126 12.9238 7.74048 12.9238 6.77368C12.9238 5.83618 13.0117 3.7854 12.6602 2.90649C12.4258 2.34985 11.9863 1.8811 11.4297 1.67603C10.5508 1.32446 8.5 1.41235 7.5625 1.41235C6.5957 1.41235 4.54492 1.32446 3.69531 1.67603C3.10938 1.9104 2.66992 2.34985 2.43555 2.90649C2.08398 3.7854 2.17188 5.83618 2.17188 6.77368C2.17188 7.74048 2.08398 9.79126 2.43555 10.6409C2.66992 11.2268 3.10938 11.6663 3.69531 11.9006C4.54492 12.2522 6.5957 12.1643 7.5625 12.1643C8.5 12.1643 10.5508 12.2522 11.4297 11.9006C11.9863 11.6663 12.4551 11.2268 12.6602 10.6409Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
export default SocialNavSection;
